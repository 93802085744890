import React from 'react';

const Header = () => {
    return (
            <header id={'header-overview'}>
                <h1>INFUSED</h1>
                <div className='tagline'>Unique handcrafted art</div>
            </header>
    )
}

export default Header;