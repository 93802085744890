import React from 'react';
import artist from '../images/debbie_at_work.png';

const About = () => {
    return (
        <div id={'about'}>
            <div className="content">
                <h1>About Debbie</h1>
                <p>Debbie is a local artist who was born and raised in San Diego, California.</p>

                <p>She works with many different types of media; with her main focus on glass, acrylic, metals, and
                    natural elements to create one of a kind art.</p>

                <p>Debbie incorporates a variety of materials in her art and uses the colors of nature to inspire her
                    projects.</p>
            </div>
            <figure>
                <img src={artist} alt="Debbie at work"/>
                <figcaption>Debbie creating a powdered glass vase</figcaption>
            </figure>
        </div>

    )
}

export default About;