import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {TextField, Button} from '@material-ui/core';
import {useForm, Controller} from 'react-hook-form';
import {init, sendForm} from 'emailjs-com';

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1)
        }
    }
}));


init("user_csMayKZL6rexHO0ac8hWD");

const Contact = () => {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [requestNumber, setRequestNumber] = useState("000000");
    const [messageSent, setMessageSent] = useState(false);

    const generateRequestNumber = () => {
        const numStr = "000000" + (Math.random() * 1000000 | 0);
        setRequestNumber(numStr.substring(numStr.length - 6));
    }

    const onSubmit = (data) => {
        generateRequestNumber();
        sendForm('default_service', 'template_eim1ddx', '#contact-form')
            .then(function (response) {
                setMessageSent(true)
            }, function (error) {
                setMessageSent(true)
            });
    }


    const classes = useStyles();

    return (
        <div id='contact'>

            {messageSent && <h1>Thank you. Your message was sent.</h1>}
            {!messageSent && (
                <>
                    <h1>Connect with Debbie</h1>
                    <div className='container'>
                        <form noValidate autoComplete="off" className={classes.root} id='contact-form'
                              onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <div className="row">
                                    <TextField id="username" name='username' label="Your name"
                                               variant="outlined" fullWidth={true}
                                               {...register("username", {required: true})}
                                    />
                                </div>
                                <div className="row">
                                    <TextField id="email" name="email" label="Your email address" variant="outlined"
                                               type='email' fullWidth={true}/>
                                </div>
                                <div className="row">
                                    <TextField name="message" multiline={true} minRows={3} label="Your message"
                                               placeholder="Your Message" fullWidth={true} variant="outlined"/>
                                </div>
                                <div className="row">
                                    <Button variant="outlined" color="primary" type="submit">
                                        Send Message
                                    </Button>
                                    <Button variant="outlined" type="reset">
                                        Clear form
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </>)}
        </div>
    )
}

export default Contact;