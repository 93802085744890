import React from 'react';
import pen from '../images/custom_pen.png';
import plate from '../images/fusedglass_plate.png';
import vase from '../images/vase.png';

const Work = () => {
    return (
        <div id='work'>
            <h1>Commissioned Work</h1>
            <div className='content'>
                <figure>
                    <img src={pen} alt="Custom hand turned pen"/>
                    <figcaption>Custom acrylic pen</figcaption>
                </figure>
                <figure>
                    <img src={plate} alt="Custom fused glass plate"/>
                    <figcaption>Fused glass plate</figcaption>
                </figure>
                <figure>
                    <img src={vase} alt="Custom vase from glass powder"/>
                    <figcaption>Vase made from powdered glass</figcaption>
                </figure>
            </div>
        </div>
    )
}

export default Work;